export class IContactUsForm {
  fullname: string;
  email: string;
  mobileNumber: string;
  location: string;
  message: string;

}

export class IApplicationForm {
  fullname: string;
  mobileNumber: string;
  email: string;
  streetAddress: string;
  city: string;
  postalCode: string;
  timeLineToOpening: string;
  territoryOfIntrest: string;
  yearOfBirth: string;
  maritalStatus: string;
  spouseName: string;
  spouseOccupdation: string;
  everFilledBankCruptcy: string;
  bankCruptcy_IfYesWhyAndHowLongAgo: string;
  haveCriminalRecord: string;
  criminalRecord_IfYesExplain: string;
  levelofIncome: string;
  firstYearExpectations: string;
  hasMeansOfFinance: string;
  currentEmployment: string;
  lengthOfEmployment: string;
  everBeenSelfEmployed: string;
  hasCleanDrivingAbstract: string;
  cleanDriving_IfNoPleaseExplain: string;
  haveAGreatCreditScore: string;
  creditScore_IfNoPleaseExplain: string;
  howDidYouHearAboutUs: string;
    crimialRecord: string;
}

export interface responseMsg {
  msg: string;
}
