import { Component, OnInit, Input } from '@angular/core';
import { log } from 'util';
declare var $: any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  @Input() fromHome: string = 'No';

  constructor() { }

  ngOnInit() {
    //let tempThis = this;
    //if (tempThis.fromHome == 'Yes') {
    //  $(document).ready(function () {
    //    console.log('doc ready called');

    //    function apply()
    //    {
    //      if (window.matchMedia('(max-width: 380px)').matches) {
    //        //$('.aboutustitle').css({ 'margin-top': '140%' });
    //        $(".aboutustitle").delay(500).css("cssText", "margin-top: 140% !important;");
    //      }

    //      else if (window.matchMedia('(max-width: 580px)').matches) {
    //        //$('.aboutustitle').css({ 'margin-top': '140%' });
    //        $(".aboutustitle").delay(500).css("cssText", "margin-top: 140% !important;");
    //      }

    //      else if (window.matchMedia('(max-width: 680px)').matches) {
    //        //$('.aboutustitle').css({ 'margin-top': '180%', 'important' });
    //        //$('.aboutustitle').style.setProperty('margin-top', '180%', 'important');
    //        $(".aboutustitle").delay(500).css("cssText", "margin-top: 180% !important;");
    //      }

    //      else if (window.matchMedia('(max-width: 850px)').matches) {
    //        //$('.aboutustitle').css({ 'margin-top': '180%' });
    //        $(".aboutustitle").delay(500).css("cssText", "margin-top: 180% !important;");
    //      }
    //    }
    //    $(window).on("orientationchange", function (event) {
    //      console.log('orientationchange');
    //      apply();
    //    });
    //    apply();
       
    //  });
    //}
  }

}
