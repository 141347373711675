import { Component, OnInit, Input } from '@angular/core';
import { IContactUsForm, responseMsg } from '../../shared/models';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../shared/data.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  @Input() fromHome: string = 'No';

  constructor(private _snackBar: MatSnackBar, private route: ActivatedRoute,
    private router: Router, private dataService: DataService) {
  }

  contactUsForm: IContactUsForm = new IContactUsForm();

  submitForm() {

    if (this.contactUsForm.message == undefined || this.contactUsForm.location == undefined || this.contactUsForm.mobileNumber == undefined
      || this.contactUsForm.email == undefined || this.contactUsForm.fullname == undefined) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Please enter all fields",
        showConfirmButton: false,
        timer: 5000
      });
    }
    else {
      let message = "Thankyou for your inquiry, we will contact you shortly.";

      this.dataService.contactRequest(this.contactUsForm)
        .subscribe(
          (data: responseMsg) => {
            this.contactUsForm = new IContactUsForm();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: message,
              showConfirmButton: false,
              timer: 1500
            });
          },
          (err: any) => console.log(err),
          () => {
            console.log("contact request sent");
          }
        );
    }
  }

  ngOnInit() {
  }

}
