import { Component, OnInit, Input } from '@angular/core';
import { IContactUsForm, responseMsg, IApplicationForm } from '../../shared/models';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../shared/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar, private route: ActivatedRoute,
    private router: Router, private dataService: DataService) {
  }


  appForm: IApplicationForm = new IApplicationForm();

  filledBank(val: string) {
    this.appForm.everFilledBankCruptcy = val;
  }



  crimialRecord(val: string) {
    this.appForm.crimialRecord = val;
  }


  selfEmployed(val: string) {
    this.appForm.everBeenSelfEmployed = val;
  }


  cleanDriver(val: string) {
    this.appForm.cleanDriving_IfNoPleaseExplain = val;
  }

  greatScore(val: string) {
    this.appForm.creditScore_IfNoPleaseExplain = val;
  }


  financeTerr(val: string) {
    this.appForm.hasMeansOfFinance = val;
  }



  submitForm() {
    if (this.appForm.fullname != undefined || this.appForm.mobileNumber != undefined
      || this.appForm.email != undefined || this.appForm.streetAddress != undefined
      || this.appForm.city != undefined || this.appForm.postalCode != undefined
      || this.appForm.yearOfBirth != undefined) {

      let message = "Thankyou for your inquiry, we will contact you shortly.";

      this.dataService.saveApplicationForm(this.appForm)
        .subscribe(
          (data: responseMsg) => {
            this.appForm = new IApplicationForm();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: message,
              showConfirmButton: false,
              timer: 1500
            });
          },
          (err: any) => console.log(err),
          () => {
            console.log("app request sent");
          }
        );
    }
    else {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Please enter all fields",
        showConfirmButton: false,
        timer: 5000
      });
    }
  }

  ngOnInit() {
  }

}
