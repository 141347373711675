import { Component, OnInit, Input } from '@angular/core';
import { IContactUsForm, responseMsg } from '../../shared/models';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../shared/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.css']
})
export class HomeComponent {
  fromHome: string = 'Yes'

  panelOpenState = false
  panelOpenState2 = false
  panelOpenState3 = false
  panelOpenState4 = false
  panelOpenState5 = false
  panelOpenState6 = false
  panelOpenState7 = false
  panelOpenState8 = false
  panelOpenState9 = false
  panelOpenState10 = false
  panelOpenState11 = false
  panelOpenState12 = false
  panelOpenState13 = false

  constructor(private _snackBar: MatSnackBar, private route: ActivatedRoute,
    private router: Router, private dataService: DataService) {
  }

  contactUsForm: IContactUsForm = new IContactUsForm();

  downloadPdf()
  {
    this.dataService.downloadPDF();
    //this.submitForm();
  }

  submitForm() {

    if (this.contactUsForm.location == undefined || this.contactUsForm.mobileNumber == undefined
      || this.contactUsForm.email == undefined || this.contactUsForm.fullname == undefined) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Please enter all fields",
        showConfirmButton: false,
        timer: 5000
      });
    }
    else {
      //let message = "Thankyou for your inquiry, we will contact you shortly.";
      this.contactUsForm.message = "Download Franchsie Kit Form";
      this.dataService.contactRequest(this.contactUsForm)
        .subscribe(
          (data: responseMsg) => {
            this.contactUsForm = new IContactUsForm();
            //Swal.fire({
            //  position: 'top-end',
            //  icon: 'success',
            //  title: message,
            //  showConfirmButton: false,
            //  timer: 1500
            //});
          },
          (err: any) => console.log(err),
          () => {
            console.log("contact request sent");
          }
        );
    }
  }
}
