import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, combineLatest, throwError, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IContactUsForm, responseMsg, IApplicationForm } from './models';
import * as FileSaver from 'file-saver';

@Injectable()
export class DataService {

  baseurl: string = 'https://customerapi.therentalguys.ca';

  //baseurl: string = 'https://localhost:44367';

 

  constructor(private http: HttpClient) { }


  contactRequest(contact: IContactUsForm): Observable<responseMsg> {
    let url = this.baseurl + `/api/ContactRequestFranchise/?Name=${contact.fullname}&Email=${contact.email}&Location=${contact.location}&Phone=${contact.mobileNumber}&Message=${contact.message}`;
    return this.http.get<responseMsg>(url).pipe();
  }


  saveApplicationForm(appForm: IApplicationForm): Observable<responseMsg> {
    var url = this.baseurl + "/api/applicationFormFranchise";
    return this.http.post<responseMsg>(url, appForm, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  downloadPDF() {
    let url = "http://customerapi.therentalevent.com/Pdf/Ebook.pdf";
    FileSaver.saveAs(url, `FranchiseKit.pdf`);
  }

  //downloadFranchiseKit() {

  //  let url = "https://customerapi.therentalevent.com/Pdf/Ebook.pdf";

  //  let fileName: string = `Franchise Kit`;

  //  this.http.get(url, { responseType: 'blob' })
  //    .subscribe((file: Blob) => {
  //      FileSaver.saveAs(file, fileName);
  //    });
  //}

  //downloadPDF(url): any {
  //  const options = { responseType: ResponseContentType.Blob };
  //  return this.http.get(url, options).map(
  //    (res) => {
  //      return new Blob([res.blob()], { type: 'application/pdf' });
  //    });
  //}
}

