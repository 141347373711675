import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor() { }

  panelOpenState = false
  panelOpenState2 = false
  panelOpenState3 = false
  panelOpenState4 = false
  panelOpenState5 = false
  panelOpenState6 = false
  panelOpenState7 = false
  panelOpenState8 = false
  panelOpenState9 = false
  panelOpenState10 = false
  panelOpenState11 = false
  panelOpenState12 = false
  panelOpenState13 = false
  panelOpenState14 = false
  ngOnInit() {
  }

}
